import React, { useState, useContext } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import noimage from "../assets/images/noimage.jpg"
import { StateContext } from '../context/ContextProvider';
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { API } from "../config/api";




const SeeallGroupcategories = () => {
  const {groupcategoryL,setGroupCategoryProduct,setGroupCategoryId} = useContext(StateContext);
  const navigate = useNavigate();
  const handleGroupCategory=async(id)=>{
  setGroupCategoryId(id);
   
    await axios
      .get(`${API}/product/groupCategoryId/${id}`)
      .then((res) => setGroupCategoryProduct(res.data.data));
    navigate('/groupcategories')
  }

//   const increment = (product_id) => {
//     setProductList((productList) =>
//       productList.map((item) =>
//         product_id === item.id ? { ...item, qty: item.qty + 1 } : item
//       )
//     );
//   };
  // const decrement = (product_id) => {
  //   setProductList((productList) =>
  //     productList.map((item) =>
  //       product_id === item.id ? { ...item, qty: item.qty - (item.qty > 0 ? 1:0) } : item
  //     )
  //   );
  // };
  

  return (
    <div className="mx-auto ms-3 crd bg-white rounded " style={{marginTop: '100px'}}>
    <div className="px-5 pb-5 ">
      <div className="d-flex bd-highlight ">
        <div className=" flex-grow-1 bd-highlight pb-4">
          <h4 style={{ marginTop: "10px" }}>Group Categories</h4>
        </div>
       
      </div>

        <div className=" text-center ">
        <div className="row ">
        {groupcategoryL.map(({ _id, title, image }, index) => (
           <div className="product-container" key={index}>
            {console.log(image.length > 0,"image")}
           <div
              className="card  h-100 w-100 bg-secondary  rounded-3  bs tody "
               onClick={()=>handleGroupCategory(_id)}>
                  {/*  <div className=" img-thumbnail text-center pb-2 mx-auto tody" <div className="item img-thumbnail text-center pb-2"> */}
                  <img
                    src={image.length > 0 ?image : noimage}
                    className="card-img-top  p-1 w-100"
                    style={{height:"200px"}}
                    alt={title}
                  />
                   <div className="card-body  h-30 pt-3">
                 
                   <h5 className="text-capitalize text-body">{title}</h5>
                    
                  </div>
               
                </div></div>
              ))}
        </div>
        </div>
        </div>
        </div>
//           <div className="container ">
//             <div className="row mb-2">
//               {productList.map((product) => (
//                 <div className="product-container" key={product}>
//                   <div className="item img-thumbnail text-center pb-1 mx-auto  mb-3 tody">
//                     <img
//                       src={product.image}
//                       className="card-img-top w-100 h-150"
//                       />

//                     <div className="card-body p-3">
//                       <h5 className="item  text-center pb-2 mx-auto  tody">
//                         {product.name}
//                       </h5>
//                       {/* <h6>{product.netWeight ? product.netWeight : 0}</h6>
//                       <div className="d-flex "> */}
//           </div> 
//             </div>
//             </div> 
//          )
//  ) }    
//          </div>
//           </div>
     );
};

export default SeeallGroupcategories ;
