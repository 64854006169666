import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";


import oil from "../assets/img/oil.jpg";
import { StateContext } from "../context/ContextProvider";

const SeeallRecommended = () => {
  const navigate = useNavigate();
  const { recommended } = useContext(StateContext);
  const HandleRecommendedList = async (id, domainType) => {
    console.log(domainType,"domainType required")
    window.sessionStorage.setItem("RecommendedId", id);
    // window.sessionStorage.setItem("domainTypeRecommendedId", domainType);

    // setDomainTypeN(domainType);
    // await axios
    //   .get(`${API}/merchant/Type/bymerchantTypeId/${id}`)
    //   .then((res) => setMerchantList(res?.data?.data));
    navigate("/recommendedList");
  };

  return (
    <div className="" style={{marginTop: '120px',marginBottom: '60px', paddingLeft: '60px'}}>
      <div className="row mb-2">
      <h4 style={{marginBottom: '20px'}}>Recommended Products</h4>
        {recommended.map((product, index) => (
          <div className="product-container" key={product}>
            <div className="item img-thumbnail text-center pb-1 mx-auto  mb-3 tody"
            onClick={() => {
              HandleRecommendedList(product?._id, product?.domainType);
            }}
            style={{
              width: "200px", height: '300px',backgroundColor: '#F5F5F5'
            }}>
              <img src={product.avatar} className="card-img-top w-100" style={{height: '150px'}} />

              <div className="card-body py-1">
                <h5 className="item  text-center pb-2 mx-auto  tody">
                  {product.firmName ? `${product.firmName.substring(0, 25)}...` : ''}
                </h5>
                {/* <h6>{product.netWeight ? product.netWeight : 0}</h6>
                      <div className="d-flex "> */}
              </div>
              <div>
                        <small>
                          <p>
                            {product.address}
                            <br /> {product.pincode}
                          </p>
                        </small>
                      </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SeeallRecommended;