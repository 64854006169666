import React from 'react'
import noproduct from '../assets/images/noproduct.png'
const NoProduct = () => {
  return (
    <div className="text-center"><img src={noproduct} alt="No product available" 
     style={{height:"70%",width:"80%"}} 
    //  style={{height:"300px",width:"500px"}} 

     /></div>
  )
}

export default NoProduct