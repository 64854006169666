import React, { useEffect,
    //  createContext,
      useState, useContext } from "react";
  import { StateContext } from "../../context/ContextProvider";
  // import { deleteFromCart, getCart } from "./helpers/localstorage";
  import { deleteFromCart, getCart } from "../../compenent/helpers/localstorage";

  import LoginPopup from "../../compenent/LoginPopup";
  import { useNavigate } from "react-router-dom";
  import cartIcon from "../../assets/images/Cart.png"
  import "./myCart.css";
  // import { setItemToLocalStorage } from "../utils/localStorageActions";
  import { setItemToLocalStorage } from "../../utils/localStorageActions";
import axios from "axios";
import { DeliveryDining } from "@mui/icons-material";

  
  // export const DeliveryContext = createContext();
  
  const MyCart = () => {
    const { cart, setCart, auth, isOpen, setIsOpen, setRedirect } =
      useContext(StateContext);
    const [dc, setDelivery] = useState(0);
  
  
    const calculateDeliveryCharges = async() => {
      console.log('calculateDeliveryCharges 111', cart[0]?.merchantId?._id, cart[0].merchantId)
      console.log('calculateDeliveryCharges 111', cart[0]?.merchantId?._id === undefined ? cart[0].merchantId : cart[0]?.merchantId?._id)

      const merchant_ID = cart[0]?.merchantId?._id === undefined ? cart[0].merchantId : cart[0]?.merchantId?._id;
      const config = {
        headers: {
          'x-token': localStorage.getItem('token'),
        } ,
      }
      const totalPrice = cart
        .map(
          (product) =>
            product?.itemPrice * product?.count
        )
        .reduce((a, b) => a + b, 0)
        .toFixed(2);

      try {
        const response = await axios.get(`https://api.orbitmart.co.in/api/v1/deliveryRate/user/${merchant_ID}`, config);
        console.log('response ........................', response.data.data[0])
        const chargeForOrderAmount = response.data.data[0];
        if(totalPrice <= 199) {
          setDelivery(chargeForOrderAmount?.chargeForOrderAmountBetween_1_199)
          console.log(chargeForOrderAmount?.chargeForOrderAmountBetween_1_199, "delivered")
        } else if(totalPrice <= 499 && totalPrice >= 200) {
          setDelivery(chargeForOrderAmount?.chargeForOrderAmountBetween_200_499)
          console.log(chargeForOrderAmount?.chargeForOrderAmountBetween_200_499, "delivered")

        }else if(totalPrice >= 500) {
          setDelivery(chargeForOrderAmount?.chargeForOrderAmountGreaterThan_500)
          console.log(chargeForOrderAmount?.chargeForOrderAmountGreaterThan_500, "delivered")

        }

        localStorage.setItem('DeliveryRate', dc)

      }catch(e) {console.log(' error delivery rate not available')}

  
      // return totalPrice >= 500 ? setDelivery(50) : setDelivery(50)
    }

    // setDelivery(13)
    useEffect(() => {
      calculateDeliveryCharges();
    });


  
    // const getDisccount = () => {
    //   console.log("cart", cart);
  
    //   if (cart.length > 0) {
    //     return cart.map((pr) => {
    //       if (pr.discountType === "FIXED") {
    //         return parseFloat(pr?.customerDiscount);
    //       } else {
    //         return (
    //           (parseFloat(pr?.itemPrice) *
    //             parseFloat(pr?.count) *
    //             parseFloat(pr?.customerDiscount)) /
    //           100
    //         );
    //       }
    //     });
    //   }
    // };
    const getDisccount = () => {
      console.log("cart", cart);
  
      if (cart.length > 0) {
        return cart.reduce((sum, pr) => {
          if (pr.discountType === "FIXED") {
            return sum + parseFloat(pr?.customerDiscount);
          } else {
            return (
              sum +
              (parseFloat(pr?.itemPrice) *
                parseFloat(pr?.count) *
                parseFloat(pr?.customerDiscount)) /
              100
            );
          }
        }, 0);
      }
      return 0; // Return 0 if cart is empty
    };

  
    const getGST = (product) => {
      if (cart.length > 0) {
        // console.log(cart);
        const discount = getDisccount()
        console.log(discount, product,"cart vikasjh")
        const totalPrice = cart
          .map((product) => (parseFloat(product?.itemPrice) - discount) * product?.count)
          .reduce((a, b) => a + b, 0);
        //   .toFixed(2) *
        //   parseFloat(product?.gst)) /
        // 100
        const gstAmount = (Number(totalPrice) * parseFloat(product?.gst)) / 100
        return gstAmount
      }
    };
  
    console.log('================================', getGST(), "================================", getDisccount())
  
  
    const getTotalGST = () => {
      let totalGST = 0;
      for (let i = 0; i < cart.length; i++) {
        const item = cart[i];
        console.log(item);
        const gst = getGST(item);
        console.log(gst);
        totalGST = gst;
      }
      return totalGST.toFixed(2);
    };
  
    console.log(getTotalGST());

    sessionStorage.setItem("getDis",getDisccount())
    sessionStorage.setItem("getGST",getTotalGST())
  
    const navigate = useNavigate();
  
    const togglePopup = () => {
      setIsOpen(!isOpen);
    };
    const handleCheckout = (evt) => {
      if (auth) {
        navigate("/shipping");
      } else {
        setRedirect(true);
        togglePopup();
      }
    };
  
    const handleQtyChange = (product, type) => {
      if (!["incr", "decr"].includes(type)) return;
  
      let updatedCart;
  
      if (type === "incr") {
        updatedCart = cart.map((cartItem) =>
          cartItem?._id === product?._id
            ? { ...cartItem, count: cartItem.count + 1 }
            : cartItem
        );
      } else {
        if (product.count === 1) {
          updatedCart = cart.filter((cartItem) => cartItem._id !== product._id);
        } else {
          updatedCart = cart.map((cartItem) =>
            cartItem?._id === product?._id
              ? { ...cartItem, count: cartItem.count - 1 }
              : cartItem
          );
        }
      }
  
      // console.log("updatedCart", updatedCart);
  
      setCart(updatedCart);
      setItemToLocalStorage("cart", updatedCart);
    };

    sessionStorage.setItem("price", cart
      .map(
        (product, index) =>
          product?.itemPrice * product?.count
      )
      .reduce((a, b) => a + b, 0) + Number(dc) + parseFloat(getTotalGST()) - getDisccount())
  
    return (
      // <DeliveryContext.Provider value={dc}>
        <div className="main-cart-container">
          {cart.length <= 0 ? (
            // <div className="mx-5 px-5 py-5 jumbotron">
            //   <h1 className="display-4">
            //     Your cart is empty{" "}
            //     <button
            //       className="btn btn-light text-primary ml-4"
            //       onClick={() => navigate(-1)}
            //     >
            //       Go Back
            //     </button>
            //   </h1>
            // </div>
            <div className="empty-container">
              <div className="empty-cart-icon">
                <img src={cartIcon} />
              </div>
              <div className="empty-container">
                <h1 className="heading-cart">Your Cart is Empty</h1>
                <p className="para-cart">"Looks like you haven't added anything to your cart yet!"</p>
                <button
                  className="btn btn-light text-primary ml-4 btn-cart"
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </button>
              </div>
              
            </div>
          ) : (
            <div className="ms-5 me-5 px-5 crd bg-white rounded mb-5">
              <h4 style={{color: '#003B95'}}>My Cart</h4>
              {isOpen && <LoginPopup handleClose={togglePopup} />}
              {/* cart item */}
              <div className="cart-page">
                <div className="small-container">
                  <table className="table-data-cart">
                    {/* <tr>
                      <th>Product</th>
                      <th>Quantity</th>
                      <th>Sub total</th>
                    </tr> */}
                    {cart.map((product) => (
                      <tr key={product._id} className="TABLE-ROW-FORcTRT">
                        <td>
                          <div className="cart-info">
                            <img
                              className="img2"
                              src={product?.image[0]}
                              alt={product?.itemName}
                            />
                            <div className="res">
                              <h6>{product?.itemName}</h6>
                              <span> ₹{product?.itemPrice}</span>
  
                              <br />
                            </div>
                          </div>
                        </td>
                        <td>
                          <span>
                            <button
                              style={{
                                width: "20px",
                                marginRight: "10px",
                                border: "0px",
                              }}
                              className="rmv"
                              onClick={() => {
                                handleQtyChange(product, "decr")
                              }}
                            >
                              -
                            </button>
                            {product.count}
                            <button
                              style={{
                                width: "20px",
                                border: "0px",
                                marginLeft: "10px",
                              }}
                              className="rmv"
                              onClick={() => {
                                handleQtyChange(product, "incr")
                              }
                              }
                            >
                              +
                            </button>
                          </span>
                          <span style={{ margon: "auto" }}>
                            <button
                              style={{
                                width: "65px",
                                border: "0px",
                              }}
                              className="delete"
                              onClick={() => {
                                deleteFromCart(product);
  
                                setCart(getCart());
                              }}
                            >
                              Delete
                            </button>
                          </span>
                        </td>
                        <td>
                          {" "}
                          <p>
                            {" "}
                            ₹{(product?.itemPrice * product?.count).toFixed(2)}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </table>
                  <div className="total-price">
                    <table className="table-total-price">
                      <tr>
                        <td>
                          Item total({" "}
                          {cart
                            .map((product, index) => product?.count)
                            .reduce((a, b) => a + b, 0)}
                          )
                        </td>
                        <td>
                          {/* ₹ {total} */}
                          {/* {console.log(total)} */}
                          {cart
                            .map(product => 
                              (product?.cartOferAamount !== undefined 
                                ? product.cartOferAamount * product?.count 
                                : product?.itemPrice * product?.count)
                            )
                            .reduce((a, b) => a + b, 0)
                            .toFixed(2)}
                        </td>
                      </tr>
                      <tr className="text-muted">
                        <td>Discount</td>
                        <td className="text-danger">₹{getDisccount()}</td>
                      </tr>
                      <tr className="text-muted">
                        <td>GST(in %)</td>
                        <td className="text-success">₹{getTotalGST()}</td>
                      </tr>
                      <tr className="text-muted">
                        <td>Delivery Charges</td>
                        <td className="text-success">₹{dc}</td>
                      </tr>
  
                      <tr>
                        <td>
                          Grand Total
                          <br />
                          <small className="text-muted">[incl. all taxes]</small>
                        </td>
                        <td>
                          ₹
                          {(
                            cart
                              // .map(
                              //   (product, index) =>
                              //     product?.itemPrice * product?.count
                              // )
                              .map(product => 
                                (product?.cartOferAamount !== undefined 
                                  ? product.cartOferAamount * product?.count 
                                  : product?.itemPrice * product?.count)
                              )
                              .reduce((a, b) => a + b, 0) + Number(dc) + parseFloat(getTotalGST()) - getDisccount()).toFixed(0)}
                        </td>
                      </tr>
  
                      <tr>
                        <td colspan="2" style={{ marginRight: "auto" }}>
                          <div
                            className="btn btn-success  btn-block proceed "
                            onClick={handleCheckout}
                          >
                            Proceed
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
    );
  };
  export default MyCart;
  