import React, { useContext, useEffect } from "react";
//import { FaRegUserCircle } from "react-icons/fa";
import axios from "axios";
//import { dataContext } from "./MerchantProvider";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../context/ContextProvider";
import noimage from "../../assets/images/noimage.jpg";
import { API } from "../../config/api.js";
import { setLocalStorage } from "../../compenent/helpers/localstorage";
import MerchantNotFound from "../../compenent/MerchantNotFound";
//import ReactPaginate from "react-paginate";
const Merchant = () => {
  const navigate = useNavigate();
  const cleanToken = localStorage.getItem("token");
  console.log(cleanToken,"asdasdsdfv")
  const token = cleanToken === null ? "" : cleanToken.replace(/^"|"$/g, '');
  console.log(token, "token is token")
  const {
    merchantList,
    setMerchantList,
    domainTypeN,
    setProductList,
    merproductcategory,
    setmerproductcategory,
    distributorId,
  } = useContext(StateContext);

  console.log(merchantList, "merchantList is set to ")

  //  setMerchantGST(parseInt([{mn:'restaurant',gst:5},{mn:'grocery',gst:10},{mn:'vegetable & fruits',gst:8}].find((dt)=>dt.mn==domainTypeN.toLowerCase()).gst))

  const getProductsById = async (merchant) => {
    setLocalStorage("firmName", merchant?.firmName);
    window.sessionStorage.setItem("merchantId", merchant?._id);
    window.sessionStorage.setItem("merchanttypeId", merchant?.merchantTypeId);
    
    const config = {
      headers: {
        // "x-token":
        // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MmIyY2JmYjA0OTczYjI3MzA0N2ZiYzYiLCJyb2xlIjoiTUVSQ0hBTlQiLCJkYXRlIjoiMjAyMi0wNi0yMlQxMDoyMToxNC4zNTNaIiwiaWF0IjoxNjU1ODkzMjc0fQ.FnZc_rzFv8jJ9nJH41cXyDlwytFLbxTmec3wKy2ksh8",
        "x-token": token,
      },
    };
    // await axios.get(`${API}/product/byMerchant/${merchant?._id}`, config).then((response) => {

    // 143.244.132.221:4002/api/v1/product/byMerchant/:id?page=2
    // await axios.get(`${API}/product/byMerchant/${merchant?._id}?page=2`, config).then((response) => {
    await axios
      .get(`${API}/product/byMerchant/${merchant?._id}`, config)
      .then((response) => {
        console.log(response.data);
        setProductList(response.data.data);

        //   //setMerchantGST(parseInt(response.data.data.find((pr)=>pr.gst!==undefined).gst))

        //   //localStorage.setItem("productL",JSON.stringify(response.data.data))})
      });
    await axios
      .get(
        `${API}/category/bymerchantTypeId/${merchant?.merchantTypeId}`,
        config
      )
      .then((response) => {
        setmerproductcategory(response.data.data);
      });

    navigate("/productList");
  };
  useEffect(() => getProductsById(), []);
  console.log("hicategoory", merproductcategory);
  // ==============render data on refresh start============

  const getMerchantById = async (id, domainType) => {
    const merchantAPI = "https://api.orbitmart.co.in/api/v1";
    const MId = window.sessionStorage.getItem("singleMerchantId");
    // const NewdomainType= window.sessionStorage.getItem("domainType")
    // setDomainTypeN(NewdomainType);
    await axios
      .get(`${merchantAPI}/merchant/Type/bymerchantTypeId/${MId}`)
      .then((res) => setMerchantList(res.data.data));
  };
  useEffect(() => {
    getMerchantById();
    //eslint-disable-next-line
  }, []);

  console.log(merchantList, "merchantList ok")

  // ==============render data on refresh end ============

  return (
    <div className="ml-5 mr-5 crd bg-white rounded " style={{marginTop:"100px"}}>
       <div className="px-4 pb-5 ">{/*  <div className="px-5 pb-5 "> */}
        <div className=" flex-grow-1 bd-highlight  pb-3">
          <h4 style={{ marginTop: "10px" }}>{domainTypeN}</h4>
        </div>

        <div className=" text-center ">
          <div className="row ">
            {merchantList.filter(
              (m) =>
                m.distributorId !== null &&
                m.distributorId._id === distributorId
            ).length > 0 ? (
              merchantList
                .filter(
                  (m) =>
                    m.distributorId !== null &&
                    m.distributorId._id === distributorId
                )
                .map((merchant, index) => (
                  <div className="product-container ">
                    {console.log(merchantList, "merchantList", !merchant.ActiveInactive &&
                          merchant.distributorStatus &&
                          merchant.distributorActiveInactive &&
                          merchant.adminActiveInactive)}
                    <div
                      className="card  h-100 w-500  rounded-3  bs tody " //card  h-100 w-500 bg-secondary  rounded-3  bs tody
                      style={{
                        background:
                          merchant.ActiveInactive &&
                          merchant.distributorStatus &&
                          merchant.distributorActiveInactive &&
                          merchant.adminActiveInactive
                            ? "#F5F5F5"
                            : "rgba(0, 0, 0,0.4)",
                        display:
                          merchant.ActiveInactive &&
                          merchant.distributorStatus &&
                          merchant.distributorActiveInactive &&
                          merchant.adminActiveInactive
                            ? "flex"
                            : "",
                        justifyContent:
                          merchant.ActiveInactive &&
                          merchant.distributorStatus &&
                          merchant.distributorActiveInactive &&
                          merchant.adminActiveInactive
                            ? "center"
                            : "",
                        alignItems:
                          merchant.ActiveInactive &&
                          merchant.distributorStatus &&
                          merchant.distributorActiveInactive &&
                          merchant.adminActiveInactive
                            ? "center"
                            : "",
                        color:
                          merchant.ActiveInactive &&
                          merchant.distributorStatus &&
                          merchant.distributorActiveInactive &&
                          merchant.adminActiveInactive
                            ? "white"
                            : "",
                      }}
                      key={index}
                      onClick={
                        !merchant.ActiveInactive &&
                        merchant.distributorStatus &&
                        merchant.distributorActiveInactive &&
                        merchant.adminActiveInactive
                          ? () => getProductsById(merchant)
                          : () => getProductsById(merchant)
                      }
                    >
                      <img
                        // className="card-img-top w-100 h-100"
                        className="card-img-top  p-1 w-100"
                        style={{ height: "200px" }}
                        src={merchant.avatar[0] ? merchant.avatar[0] : noimage}
                        alt={merchant.firmName}
                      />

                      <div className="card-body  h-30 pt-3">
                        <h5 className="text-capitalize text-body">
                          {merchant.firmName}
                        </h5>
                        <small>
                          <p>
                            {merchant.address}
                            <br /> {merchant.pincode}
                          </p>
                        </small>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <MerchantNotFound />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Merchant;
