import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./orderPlace.css"
import { API } from "../../config/api";
import { StateContext } from "../../context/ContextProvider";
import tickMark from "../../assets/images/Group 257.png"
const OrderPlaced = () => {
  const navigate = useNavigate();
    const { setMyOrder, setOrderInfo} = useContext(StateContext);
  const handleTrack = async () => {
    const res = await axios.get(
      `${API}/order/user/${localStorage.getItem("userId")}`
    );
    setOrderInfo(res.data?.data[0])
    setMyOrder(res.data.data);

    // await axios
    //   .get(`${API}/order/user/${localStorage.getItem("userId")}`)
    //   .then((res) => setMyOrder(res.data.data));

    // navigate("/myorder");
    navigate("/OrderDetails");
  };
  return (
    <div
      className=" mx-auto px-auto text-center py-5 main-container-orderPlace"
      style={{ width: "300px" }}
    >
      <div className="row orderPlace-border-less  d-flex g-3 justify-content-center  ">
        <img
        src={tickMark}
        //   src="https://th.bing.com/th/id/R.f166c8aef87a525724706b1fefe9b625?rik=YwScQm%2fCflyDdg&riu=http%3a%2f%2fcliparts.co%2fcliparts%2fBTa%2fELz%2fBTaELzrgc.png&ehk=cnosR2%2f1CRFkmX5B3vLHCtUCVCeRhE3WYIN5olD8e%2fo%3d&risl=&pid=ImgRaw&r=0"
          alt="success"
          style={{ margin: "auto" }}
          className="w-75 h-75"
        />

        <h6 style={{ marginTop: "10px", color: "#003C7E", paddingTop: "15px", fontWeight: '600', fontSize: '19px'}}>
          "Order Placed Successfully"
        </h6>
        <button
          type="button"
          className="btn btn-info btntract"
          style={{ width: "300px", backgroundColor: "#003C7E", color: "white" }}
          onClick={handleTrack}
        >
          Track your order
        </button>

        <button
          type="button"
          className="btn btn-success shopingContiBtn"
          style={{
            width: "300px",
            backgroundColor: "skyblue",
            color: "#003C7E",
          }}
          onClick={(e) => navigate("/")}
        >
          Continue for shopping
        </button>
      </div>
    </div>
    // </div>
    // <div></div>
    // </div>
  );
};

export default OrderPlaced;
