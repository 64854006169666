import React, { useState, useContext, useEffect } from "react";
import { StateContext } from "../context/ContextProvider";
import oil from "../assets/img/oil.jpg";
import { AiFillStar } from "react-icons/ai";
import axios from "axios";
import { addToCart, deleteFromCart, getCart } from "./helpers/localstorage";
import { API } from "../config/api"

const BestOfferall = () => {
  const { offers } = useContext(StateContext);
  console.log(offers);
  const { pincode } = useContext(StateContext);
  const { setCart } = useContext(StateContext);
  const [offersData, setOffersData] = useState([]);

  function getOffers() {
    // debugger;
    axios
      .get(`${API}/offer/merchants/${pincode}`)
      .then((res) => {
        // debugger;
        console.log(res);
        setOffersData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getOffers();
  }, []);

  const addCartProduct = (productId, amount = undefined) => {
    productId.cartOferAamount = amount;
    console.log(productId);
    addToCart(productId);
    setCart(getCart());
  };

  const fixValue = "Rs Off";

  const perValue = "% Off";
  return (
    <div style={{marginTop: '100px'}}>
      <div>
        <h1
          style={{ marginLeft: 45, marginTop: 10, color: '#66cdaa', fontSize: '2rem' }}
          className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-12"
        >
          Best Offers
        </h1>
      </div>
      <div style={{
        // border: '1px solid red',
        margin: '20px'
      }}>
     <div className="row mb-2" style={{ border: "none" }}>
  {offersData
    .filter((data) => data.offerType === "BEST")
    .map(({ discountType, offerDiscount, productId, userId, _id }) => (
      productId.map((product, index) => (
        <div className="product-container" key={`${_id}-${index}`}>
          <div
            className="item img-thumbnail text-center pb-1 mb-3 tody"
            style={{ marginLeft: "0", marginRight: "0" }}
          >
            <p className="bg-gr p-2 text-white rounded-top fw-bold">
              {discountType === "FIXED"
                ? offerDiscount + " " + fixValue
                : offerDiscount + " " + perValue}
              <br />
              {userId.firmName}
            </p>
            <div style={{ width: "100%" }}>
              <img
                src={product.image}
                style={{
                  paddingTop: "1px",
                  width: "100%",
                  height: "120px",
                }}
                alt={`Product ${index}`}
              />
            </div>
            <div className="d-flex fw-bold justify-content-between ps-3 pe-3 pb-3">
              <div className="ps-2 pe-2">
                {product.itemName}
              </div>
              <div className="fw-bold m-2 text-body1">
                <p
                  style={{
                    fontSize: "18px",
                    color: "#000",
                    margin: "0",
                  }}
                >
                  ₹{" "}
                  {discountType === "FIXED"
                    ? Math.round(product.itemPrice - offerDiscount)
                    : Math.round(
                        (
                          product.itemPrice -
                          (product.itemPrice * offerDiscount) / 100
                        ).toFixed(2)
                      )}
                </p>
                <del
                  style={{
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  ₹ {product.itemPrice}
                </del>
              </div>
            </div>
            <button
              type="button"
              className="btn-sm txt-bl fw-bold w-100 text-center mx-auto"
              onClick={() =>
                addCartProduct(
                  product,
                  discountType === "FIXED"
                    ? Math.round(product.itemPrice - offerDiscount)
                    : Math.round(
                        (
                          product.itemPrice -
                          (product.itemPrice * offerDiscount) / 100
                        ).toFixed(2)
                      )
                )
              }
            >
              Add
            </button>
          </div>
        </div>
      ))
    ))}
</div>

      </div>
    </div>
  );
};

export default BestOfferall;
