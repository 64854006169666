import React, { useState, useContext, useEffect } from "react";
import { StateContext } from "../context/ContextProvider";
import oil from "../assets/img/oil.jpg";
import { AiFillStar } from "react-icons/ai";
import axios from "axios";
import { addToCart, deleteFromCart, getCart } from "./helpers/localstorage";

const Todaydealsseeall = () => {
  const { offers } = useContext(StateContext);
  console.log(offers);
  const { pincode } = useContext(StateContext);
  const { setCart } = useContext(StateContext);
  const [offersData, setOffersData] = useState([]);

  function getOffers() {
    // debugger;
    axios
      .get(`https://api.orbitmart.co.in/api/v1/offer/merchants/${pincode}`)
      .then((res) => {
        // debugger;
        console.log(res);
        setOffersData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getOffers();
  }, []);

  const fixValue = "Rs Off";

  const perValue = "% Off";

  //   const increment = (product_id) => {
  //     setProductList((productList) =>
  //       productList.map((item) =>
  //         product_id === item.id ? { ...item, qty: item.qty + 1 } : item
  //       )
  //     );
  //   };
  // const decrement = (product_id) => {
  //   setProductList((productList) =>
  //     productList.map((item) =>
  //       product_id === item.id
  //         ? { ...item, qty: item.qty - (item.qty > 0 ? 1 : 0) }
  //         : item
  //     )
  //   );
  // };

  const addCartProduct = (productId, amount = undefined) => {
    productId.cartOferAamount = amount;
    console.log(productId);
    addToCart(productId);
    setCart(getCart());
  };

  return (
    <div style={{marginTop: '100px'}}>
      <div>
        <h1
          style={{ marginLeft: 45, marginTop: 10,color: '#66cdaa', fontSize: '2rem' }}
          className="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-12"
        >
          Today's Offers
        </h1>
      </div>
      <div className="" style={{
        // border: '1px solid red',
        margin: '20px'
      }}>
        <div className="row mb-2" style={{ border: "none" }}>
  {offersData
    .filter((data) => data.offerType === "TODAY")
    .map(({ discountType, offerDiscount, productId, userId, _id }) => (
      productId.map((product, index) => (
        <div className="product-container" key={`${_id}-${index}`}>
          <div
            className="item img-thumbnail text-center pb-1 mb-3 tody"
            style={{ marginLeft: "0", marginRight: "0" }}
          >
            <p className="bg-gr p-2 text-white rounded-top fw-bold">
              {discountType === "FIXED"
                ? offerDiscount + " " + fixValue
                : offerDiscount + " " + perValue}
              <br />
              {userId.firmName}
            </p>
            <div style={{ width: "100%" }}>
              <img
                src={product.image}
                style={{
                  paddingTop: "1px",
                  width: "100%",
                  height: "120px",
                }}
                alt={`Product ${index}`}
              />
            </div>
            <div className="d-flex justify-content-between ps-3 pe-3 pb-3">
              <div className="ps-2 pe-2">
                {product.itemName}
              </div>
              <div className="fw-bold m-2 text-body1">
                <p
                  style={{
                    fontSize: "18px",
                    color: "#000",
                    margin: "0",
                  }}
                >
                  ₹{" "}
                  {discountType === "FIXED"
                    ? Math.round(product.itemPrice - offerDiscount)
                    : Math.round(
                        (
                          product.itemPrice -
                          (product.itemPrice * offerDiscount) / 100
                        ).toFixed(2)
                      )}
                </p>
                <del
                  style={{
                    fontSize: "13px",
                    color: "red",
                  }}
                >
                  ₹ {product.itemPrice}
                </del>
              </div>
            </div>

            <button
              type="button"
              className="btn-sm txt-bl fw-bold w-100 text-center mx-auto"
              onClick={() =>
                addCartProduct(
                  product,
                  discountType === "FIXED"
                    ? Math.round(product.itemPrice - offerDiscount)
                    : Math.round(
                        (
                          product.itemPrice -
                          (product.itemPrice * offerDiscount) / 100
                        ).toFixed(2)
                      )
                )
              }
            >
              Add
            </button>
          </div>
        </div>
      ))
    ))}
</div>

        {/* <div className="row mb-2"style={{border: 'none'}}>
          {offersData
            .filter((data) => data.offerType === "TODAY")
            .map(
              (
                { discountType, offerDiscount, productId, userId, _id },
                index
              ) => (
                <div className="product-container" key={_id}>
                  <div className="item img-thumbnail text-center pb-1  mb-3 tody" style={{marginLeft :'0', marginRight: '0'}}>
                    <p className=" bg-gr p-2 text-white rounded-top fw-bold">
                      {discountType == "FIXED"
                        ? offerDiscount + "" + fixValue
                        : offerDiscount + "" + perValue}
                      <br />
                      {userId.firmName}
                    </p>
                    <div style={{width: '100%'}}>
                    <img
                      src={productId[0].image}
                      style={{
                        paddingTop: "1px",
                        width: "100%",
                        height: "120px",
                      }}
                    />
                   </div>
                    <h6 className="p-3 fw-bold pt-4">
                      {productId[0].itemName}
                    </h6>
                    <div className="d-flex justify-content-between ps-3 pe-3 pb-3">
                      <div className=" ps-2 pe-2">
                        {4.2} <AiFillStar />
                        {productId[0].itemName}
                      </div>
                      <div className="fw-bold m-2 text-body1">
                        ₹{productId[0].itemPrice}
                        <p style={{fontSize: '18px', color: '#000', margin: '0'}}>
                       ₹ {discountType === "FIXED"
                                        ? Math.round(
                                          productId[0].itemPrice - offerDiscount
                                          )
                                        : Math.round(
                                            (
                                              productId[0].itemPrice -
                                              (productId[0].itemPrice *
                                                offerDiscount) /
                                                100
                                            ).toFixed(2)
                                          )}{" "}
                       </p>
                       <br/>
                       <del
                                      style={{
                                        fontSize: "13px",
                                        color: "red",
                                      }}
                                    >
                                     ₹ {productId[0].itemPrice}
                                    </del>{" "}
                      </div>
                    </div>

                    <button
                      type="button"
                      className="btn-sm txt-bl fw-bold w-100 text-center mx-auto"
                      onClick={() => addCartProduct(productId, 
                        discountType === "FIXED"
                                        ? Math.round(
                                          productId[0].itemPrice - offerDiscount
                                          )
                                        : Math.round(
                                            (
                                              productId[0].itemPrice -
                                              (productId[0].itemPrice *
                                                offerDiscount) /
                                                100
                                            ).toFixed(2)
                                          )
                      )}
                    >
                      Add
                    </button>
                  </div>
                </div>
              )
            )}
        </div> */}
      </div>
    </div>
  );
};

export default Todaydealsseeall;
